import React, { useState } from 'react';
import { NavContainer, NavButton, Icon } from './styled';
import { FaClockIcon, FaStarIcon, FaThLargeIcon } from './styled';
import { updateLastReportFilter } from 'services/api/users';

const CardsFilters = ({ onNavClick, thisUser }) => {
  const [selected, setSelected] = useState(thisUser.last_report_filter); // Alterado para usar thisUser.last_report_filter

  const handleNavClick = (type) => {
    setSelected(type);
    onNavClick(type);
    updateLastReportFilter(thisUser.id, {last_report_filter: type});
  };

  return (
    <NavContainer>
      <NavButton selected={selected === 'recentes'} onClick={() => handleNavClick('recentes')}>
        <Icon selected={selected === 'recentes'}><FaClockIcon /></Icon>
        <h1>Recentes</h1>
      </NavButton>
      <NavButton selected={selected === 'favoritos'} onClick={() => handleNavClick('favoritos')}>
        <Icon selected={selected === 'favoritos'}><FaStarIcon /></Icon>
        <h1>Favoritos</h1>
      </NavButton>
      <NavButton selected={selected === 'meusAplicativos'} onClick={() => handleNavClick('meusAplicativos')}>
        <Icon selected={selected === 'meusAplicativos'}><FaThLargeIcon /></Icon>
        <h1>Meus cards</h1>
      </NavButton>
    </NavContainer>
  );
};

export default CardsFilters;