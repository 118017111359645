import styled from 'styled-components'

export const CardTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-grey);
    color: var(--color-font);
    // padding: 15px;
    & p {
        
        padding: 15px 30px;
        /* flex-grow: 1; */
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
    }
    & div {
        padding: 15px;
    }
    @media (max-width: 650px) {
        & {
            flex-direction: column;
            padding-left: 0px;
        }
        & p {
        // background-color: green;
        // padding: 5px 0;
        // padding-left: 0px;
        /* flex-grow: 1; */
        // font-size: 20px;
        // line-height: 24px;
        // font-weight: bold;
    }
        & div {
        padding: 8px 10px;
        padding-bottom: 5px;
        // background-color: red;
    }
    }
    // // @media(max-width: 500px) {
    // //     flex-direction: column;
    // //     & p {
    // //         padding-left: 0px;
    // //     }
    // }
`

export const CardTitlep = styled.div`
    padding: 15px 0px;
    padding-left: 30px;
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;

    @media (max-width: 650px) {
        & {
            padding: 20px 0px;
        }
    }
`